<template>
<v-container fluid class="pa-0">
    <PageHeader :mapLink="false" :title="'Utilisateur :' + item.usr_lastName" :contextStyle="contextStyle"></PageHeader>

    <!-- Formulaire -->
    <v-card fluid class="ma-4 pa-5 elevation-2 rounded-lg">
        <v-layout row wrap>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Nom"    v-model="item.usr_lastName" ></v-text-field>
            <v-text-field label="Prénom" v-model="item.usr_firstName"></v-text-field>
            <v-text-field label="Login"  v-model="item.usr_username"></v-text-field>
            <v-text-field label="Mot de passe" type="password" v-model="item.usr_password" loading>
                <template v-slot:progress>
                    <v-progress-linear :value="progress" :color="color" height="3" absolute></v-progress-linear>
                </template>
            </v-text-field>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-text-field label="Tel" v-model="item.usr_phone"></v-text-field>
            <v-text-field label="Mobile" v-model="item.usr_mobile"></v-text-field>
            <v-text-field label="Courriel" v-model="item.usr_email"></v-text-field>
            <v-select :items="roleList" item-value="value" v-model="item.usr_role" label="Role" single-line >
            </v-select>
        </v-flex>
        <v-flex xs4 class="pa-2">
            <v-container fluid class="text-right">
            <v-chip outlined color="blue">
                <v-icon left>mdi-information-outline</v-icon>Modification: {{ $date(item.usr_updated_at).format('DD MMMM YYYY') }}
            </v-chip>
            </v-container>
        </v-flex>
        <v-flex xs4 class="pa-2"></v-flex>
        </v-layout>
        <v-card-actions>
            <v-btn color="red" v-if="item.usr_uid" @click="deleteUser(item)"><v-icon title="Supprimer">mdi-delete</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="saveProgress" @click.native="saveUser()" :disabled="disabledSave">Enregistrer</v-btn>
            <v-btn color="primmary" href="/#/users/">Retour</v-btn>
        </v-card-actions>
    </v-card>
</v-container>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader'
import UserMixin from '@/mixins/UserMixin'
import { cacheGetters } from "@/store/cache.module"

export default {
    name: 'user_card',
    props: ['contextStyle'],
    mixins: [UserMixin],
    components: {
        PageHeader
    },
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            roleList: [
            {'value': 'ADMIN'         , 'text': 'Administrateur'},
            {'value': 'CONDUCTEUR'    , 'text': 'Robert: conduc travaux'},
            {'value': 'CHEFEQUIPE'    , 'text': 'Robert: chef chantier'},
            {'value': 'AGENT'         , 'text': 'Robert: Agent'},
            {'value': 'INTERVENTION'  , 'text': 'Intevention'},
            {'value': 'CONSULTATION'  , 'text': 'Consultation'},
            {'value': 'SCE_CHIEFUNIT' , 'text': 'Chef d’Unité Sceaux'},
            {'value': 'SCE_MANAGER'   , 'text': 'Responsable technique du parc '},
            {'value': 'SCE_ASSISTANT' , 'text': 'Responsable technique du parc '},
            ],
            item:{
              usr_id: 0, usr_uid:'', usr_username: '', usr_password: '',
              usr_lastName: '', usr_firstName: '', usr_middleName: '',
              usr_phone: '', usr_mobile: '', usr_email: '',
              usr_created_at: '2000-01-01', usr_updated_at: '',
              usr_isActive: 1, sta_id_type: 'USR01', usr_role: 'BASIC_USER'
            },
        }
    },
    computed: {
        progress () {
            return Math.min(100, this.item.usr_password.length * 12.5)
        },
        color () {
            return ['error', 'warning', 'success'][Math.floor(this.progress / 50)]
        },
        disabledSave(){
            if(this.saveProgress){
                return true
            }
            if( this.item.usr_email.length > 2 ){
                return false
            } else {
                return true
            }
        }
    },
    mounted:function (){
        this.loadItem()
    },
    methods: {
        async loadItem(){
            if( parseInt( this.$route.params.id ) > 0 && this.$route.params.id !== undefined ){
                
                try {
                    let response = await this.fetchUser(this.$route.params.id)
                    var prop
                    for( prop in response ){
                        if( this.item[prop] !== undefined ){
                            this.item[prop] = response[prop] 
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        },
        async saveUser () {
            // Enregistrement du formulaire
            //this.saveProgress = true
            if( parseInt( this.$route.params.id ) > 0 ){
                try {
                    await this.updateUser(this.item)
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                } catch (error) {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                }
            } else {
                try {
                    await this.createUser(this.item)
                    this.$root.$toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/users' })
                } catch (error) {
                    this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                }
            }
        },
        deleteUser(item){
            this.$root.$confirm('Supprimer l\'utilisateur (' + item.id + ')', 'Confirmez vous cette action ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.saveProgress = true
                    this.$http.delete( '/users/' + item.id).then( () => {
                        this.$root.$toast({ color: 'success', text: 'Utilisateur supprimé !' })
                        this.saveProgress = false
                        this.$router.push({ path: '/users' })
                    }).catch( (error) => {
                        this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                        this.saveProgress = false
                    })
                }
            })
        },
        getCacheSession : cacheGetters.getSession,
    }
}
</script>